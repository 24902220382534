.brand-name {
    font-style: italic;
    color: #19639b;
    text-decoration: none;
    transition-duration: 500ms;
    display: flex;
}

.brand-name img {
    /* width: 30px; */
    height: 50px; 
    /* margin-left: 5px; */
}

.brand-name:visited {
    color: #19639b;
}

.brand-name:hover {
    color: #19639b !important;
}