.leadership {
    font-family: inherit;
}

.about-carousel img {
    height: 32rem;
    object-fit: contain !important;
    object-position: center;
}

.overlay {
    background: rgba(0, 0, 0, 0.75);
}

.overlay {
    height: 32rem;
    width: 100%;
    position: absolute;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7))
}

.ab-slideshow-caption {
    width: fit-content;
    padding: 5px;
    background: rgba(0, 0, 0, 0.65);
}